import { writable } from 'svelte/store'
import { browser } from '$app/environment'

export const isMobileNavOpen = writable(false)
export const is_mobile_nav_open = writable(false)
export const is_dimmed = writable(false)
export const is_sub_menu_open = writable(false)

type Cookie_Policy = [string, boolean]

export type Consent_Settings = {
	is_banner_visible: boolean
	policies: Cookie_Policy[]
}

// Reading cookie consent settings from local storage
const consent_settings: Consent_Settings = JSON.parse(
	(browser && localStorage.cookieSettings) || 'null',
) || {
	is_banner_visible: true,
	policies: [
		['necessary_storage', true],
		['ad_storage', false],
		['analytics_storage', false],
		['functional_storage', false],
		['personalization_storage', false],
		['security_storage', false],
	],
}

export const storage_settings = writable(browser && consent_settings)

// Writing cookieSettings to local storage
storage_settings.subscribe((val) => browser && (localStorage.cookieSettings = JSON.stringify(val)))
